.offerPlate {
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 37% 20%;
  gap: 3%;
  border-radius: 2px;

  &::after {
    content: '';
    background: #232b3a;
    position: absolute;
    top: 0;
    right: 0;
    width: 95%;
    height: 100%;
    z-index: -1;
    border-radius: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  .offerPlateLabel {
    position: absolute;
    top: -25px;
    right: 10px;
    font-size: 12px;
  }

  .imgBlock {
    position: relative;
    transform: translateX(-5px);
    margin-right: 16px;
  }

  .imageWrapper {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
  }

  .statusOnline {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -5px;
    right: -5px;

    &.active {
      background: green;
    }

    &:not(.active) {
      background: gray;
    }
  }
}

@import '../../../styles/mixins.scss';

.profileInfo {
  position: relative;

  &:not(.modal) {
    padding: 24px;
    background-color: #232b3a;
    border-radius: 3px;
  }
}

.section {
  position: relative;
  display: grid;
  gap: 3%;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 30% 30% 34%;
  }
}

.wrapper {
  @include media-breakpoint-down(xs){
    display: grid;
    grid-template-columns: 1fr;
    gap: 3%;
  }

  @include media-breakpoint-between(xs,lg) {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 3%;
  }

  @include media-breakpoint-up(lg) {
    display: contents;
  }
}

.imgWrapper {
  width: 100%;

  .img {
    cursor: pointer;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 260px;
    border-radius: 5px;
    overflow: hidden;

    @include media-breakpoint-up(xs) {
      max-height: 340px;
    }
  }
}

.statusOnline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -5px;
  right: -5px;

  &.active {
    background: green;
  }

  &:not(.active) {
    background: gray;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.statistics {
  width: 100%;
  height: 100%;
}

.btnEditWrapper {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}

.btnEdit {
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #1a202c;
  margin: 0 auto;
  transition: 0.3s ease;
  border-radius: 12px;

  box-shadow: 0 0 4px 1px rgb(0 0 0 / 40%);
  font-weight: normal;
  letter-spacing: 2px;
  /* font-family: "europeext"; */
  border-radius: 3px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  .item {
    border-radius: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3d485b;
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        text-align: center;

        &:focus-visible {
            outline: none;
        }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

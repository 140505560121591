.section {
  &:not(.modal) {
    padding: 20px;
  }

  .wrapper {
    border-radius: 3px;

    &:not(.modal) {
      padding: 20px;
      margin-bottom: 12px;
      background: #1a202c;
    }
  }
}

$breakpoints-xs: 540px;
$breakpoints-sm: 640px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1080px;
$breakpoints-2xl: 1140px;

$breakpoints: (
  xs: $breakpoints-xs,
  sm: $breakpoints-sm,
  md: $breakpoints-md,
  lg: $breakpoints-lg,
  xl: $breakpoints-xl,
  xxl: $breakpoints-2xl,
);

@mixin media-breakpoint-up($name, $grid-breakpoints: $breakpoints) {
  @if map-has-key($grid-breakpoints, $name) {
    $min: map-get($grid-breakpoints, $name);

    @media (min-width: $min) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$name}.';
  }
}

@mixin media-breakpoint-down($name, $grid-breakpoints: $breakpoints) {
  @if map-has-key($grid-breakpoints, $name) {
    $max: map-get($grid-breakpoints, $name);

    @media (max-width: ($max - 0.98)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$name}.';
  }
}

@mixin media-breakpoint-between($lower, $upper, $grid-breakpoints: $breakpoints) {
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    $min: map-get($grid-breakpoints, $lower);
    $max: map-get($grid-breakpoints, $upper);

    @media (min-width: $min) and (max-width: ($max - 0.98)) {
      @content;
    }
  } @else {
    @if (map-has-key($grid-breakpoints, $lower) ==false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map-has-key($grid-breakpoints, $upper) ==false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@import '../../../styles/mixins.scss';

.block {
  display: flex;
  flex-direction: column;

  .dateMobile {
    font-size: 12px;
    padding: 2px;
    font-weight: bold;

    @include media-breakpoint-up(xl){
      display: none;
    }
  }
}

.item {
  position: relative;

  @include media-breakpoint-down(xl) {
    background: #232b3a;
    border-radius: 8px;
    padding: 10px;
  }

  .statusOnline {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -1px;

    &.active {
      background: green;
    }

    &:not(.active) {
      background: gray;
    }
  }

  .top {
    @include media-breakpoint-down(xl) {
      display: grid;
      grid-template-columns: 80px auto;
      gap: 10px;
    }
    @include media-breakpoint-up(xl) {
      display: contents;
    }
  }

  .wrapper {
    cursor: pointer;
    position: relative;

    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    @include media-breakpoint-up(xl) {
      display: grid;
      align-items: center;
      grid-template-columns: 80px 40% 10% 13% 6% 10%;
      padding: 10px 0;
      gap: 2%;

      &::after {
        content: '';
        background: #232b3a;
        position: absolute;
        top: 0;
        right: 0;
        width: 95%;
        height: 100%;
        z-index: -1;
        border-radius: 4px;
      }
    }
  }

  .imgWrapper {
    position: relative;
    display: block;
    min-width: 80px;
    max-width: 80px;

    min-height: 80px;
    max-height: 80px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 4px;
      box-shadow:0 0 2px 0 black;
      margin-left:-5px;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 12px;
  }

  .name {
    font-size: 12px;
    color: white;
  }

  .id {
    font-size: 12px;
    color: #919eb0;
  }

  .columns small{
    font-size: 14px;
  }
  .columns {
    @include media-breakpoint-down(xl) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
      display: contents;
    }
  }

  .col {
    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .colLabel {
    color: #919eb0;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  .colValue {
    color: white;
    font-size: 18px;
    line-height: 18px;
  }

  .bottom {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xl) {
      justify-content: center;
      margin-top: 25px;
    }

    @include media-breakpoint-up(xl) {
      width: 95%;
      margin-left: auto;
      justify-content: space-between;
      margin-top: 12px;
    }
  }

  .date {
    font-size: 12px;
    padding: 2px;
    font-weight: bold;

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  .btn {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    background: #232b3a;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 18px;
      width: 100%;
      height: 100%;
    }
  }
}
.grid.sm:grid-cols-2.gap-4 > div:hover 
{
  opacity: 0.2;
}
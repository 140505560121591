@import '../../styles/mixins.scss';

.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3%;
  z-index: 9998;
  transition: 0.5s ease;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &:not(.active) {
    opacity: 0;
    pointer-events: none;
  }

  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }

  .wrapper {
    position: relative;
    height: fit-content;
    background: #1a202c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    z-index: 560;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    transition: 0.3s ease;

    padding: 16px;
    padding-right: 8px;

    @include media-breakpoint-down(xs) {
      max-width: 90%;
    }

    @include media-breakpoint-between(xs, xxl) {
      max-width: 80%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1100px;
    }

    &.active {
      top: 0;
      opacity: 1;
      visibility: visible;
    }

    &:not(.active) {
      top: -100%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(21 26 35 / 1);
    opacity: 0.8;
    transition: 0.3s ease;

    &.active {
      opacity: 0.8;
    }

    &:not(.active) {
      opacity: 0;
      pointer-events: none;
    }
  }

  .close {
    position: absolute;
    top: -50px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #1a202c;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;

    @include media-breakpoint-up(sm) {
      top: 0;
      right: -50px;
    }
  }
}
